<template>
  <div>
    <Details :isOpen.sync="showChangeStatusModal" :data="modalData" :restrictions="restrictions" />
    <Table
      :focusable="false"
      :loading.sync="oLoading.table"
      :perPage="20"
      :show-detail-icon="false"
      apiUrl="activity_logger"
      class="dispatch-log-table"
      :params="aParams"
      paginated
      ref="table"
    >
      <b-table-column :label="$t('global.idLog')" width="100" v-slot="props">
        {{ props.row.user_id || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.date')" v-slot="props">
        <DateTime :date="props.row.date" format="MM/DD/Y HH:mm:ss" />
      </b-table-column>
      <b-table-column :label="$tc('global.logType')" v-slot="props">
        {{ props.row.resource_path || '-' }}
      </b-table-column>
      <b-table-column :label="$tc('global.userName')" v-slot="props">
        {{ props.row.user_name || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.action')" v-slot="props">
        {{ props.row.action_type || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.resourceId')" v-slot="props">
        {{ props.row.resource_id || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.oldAndNew')" v-slot="props">
        <div class="flex details">
          <Link
            v-if="
              props.row.action_type !== 'read' &&
              (props.row.old_data || props.row.new_data) &&
              props.row.old_data !== props.row.new_data
            "
            @click="() => openModal(props.row)"
            class="ml-5 mr-5"
          >
            {{ $t('global.seeDetails') }}
          </Link>
        </div>
      </b-table-column>
    </Table>
  </div>
</template>

<script>
import Details from './Details.vue';
import { DateTime, Link, Table } from '@/components';
import { dataLogs } from './data';

export default {
  components: {
    DateTime,
    Details,
    Link,
    Table
  },
  data() {
    return {
      oLoading: { table: this.loading },
      showChangeStatusModal: false,
      modalData: null,
      restrictions: {
        new_values: false,
        old_values: false
      }
    };
  },
  computed: {
    bTable() {
      return this.$refs?.table?.bTable;
    },
    aParams() {
      let aParams = [];
      Object.keys(this.params).map((key) => {
        let value = this.params[key];
        if (key == 'start_date') value += '  00:00:00';
        if (key == 'end_date') {
          value = this.$moment(value).add(1, 'day').format('Y-MM-DD');
          value += '  00:00:00';
        }
        if (value) aParams.push(`${key}=${value}`);
      });

      return aParams;
    }
  },
  methods: {
    async getData() {
      await this.$refs.table.getData({ clear: true });
    },
    openModal(data) {
      const { old_data, new_data } = data;
      const oldData = old_data ? JSON.parse(old_data) : {};
      const newData = new_data ? JSON.parse(new_data) : {};
      let currentData = [];

      dataLogs.map((e) => {
        let oldValue = null;
        let newValue = null;
        if (newData[e.key] && newData) oldValue = newData[e.key];
        if (oldData[e.key] && oldData) newValue = oldData[e.key];
        if (newData[e.key] || oldData[e.key]) {
          currentData.push({
            old: oldValue,
            new: newValue,
            id: e.label
          });
        }
      });

      if (
        currentData.find((e) => {
          return e.old !== null;
        })
      ) {
        this.restrictions.old_values = true;
      }

      if (
        currentData.find((e) => {
          return e.new !== null;
        })
      ) {
        this.restrictions.new_values = true;
      }

      this.modalData = currentData;
      this.showChangeStatusModal = true;
    }
  },
  watch: {
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    params: { type: Object, default: () => {} }
  }
};
</script>
<style lang="sass" scoped>
.dispatch-log-table
  .details
    font-weight: 700
    text-decoration-line: underline
    text-decoration-color: $blue-500
  ::v-deep
    .table-wrapper
      height: calc(100vh - 320px)
</style>

<style lang="sass">
.dark
  .dispatch-log-table
    .details
      text-decoration-color: $blue-400
</style>
