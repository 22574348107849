<template>
  <Card id="changes-log" />
</template>

<script>
import Card from '@/components/Dispatch/ChangeLog/Card';

export default {
  components: {
    Card
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', ['dispatch.changeLog']);
  }
};
</script>

<style lang="sass" scoped>
#changes-log
  height: calc(100% - 100px)
  margin: 0
  #dispatch-table-side
    width: 100%
    margin-top: 20px
    margin-right: 15px
    #unattended-deliveries-table
      margin-top: 0
    #tableHeader
      margin-bottom: -35px
  #dispatch-manifest-side
    width: 450px
  ::v-deep
    .card-content,
    .card-content > .flex
      height: 100%
    .datepicker
      margin-bottom: 0
    #dispatch-manifest-side #manifest-header-slot
      margin-bottom: 1rem
    #dispatch-table-side
      .tabs li
        font-weight: bold
        &:not(.is-active) a
          color: $gray-400
          border-bottom-color: $gray-400
</style>
