<template>
  <div class="filters flex w-100">
    <Autocomplete
      :data="dataLogs"
      :disabled="disabled"
      :label="$t('global.logType')"
      class="mr-auto"
      v-model="params.resource_path"
      :loading="loading.filters"
    />
    <Autocomplete
      :data="dataActions"
      :disabled="disabled"
      :label="$t('global.action')"
      v-model="params.action_type"
      :loading="loading.filters"
    />
    <DatePicker
      class="mb-3 ml-3"
      v-model="params.date"
      icon-right="calendar-today"
      :disabled="disabled"
      :maxDate="maxDate"
      position="is-bottom-left"
    />
  </div>
</template>

<script>
import { Autocomplete, DatePicker } from '@/components';

export default {
  components: {
    Autocomplete,
    DatePicker
  },
  computed: {
    maxDate() {
      return this.$moment().toDate();
    }
  },
  created() {
    this.params.date = this.$moment(new Date()).format('Y-MM-DD');
  },
  data() {
    return {
      loading: { filters: false },
      params: {
        resource_path: null,
        action_type: null,
        date: null
      },
      dataActions: ['read', 'update', 'create', 'delete'],
      dataLogs: [
        'cdp',
        'close_door_pharmacy',
        'deliveries_inbox',
        'delivery_event',
        'delivery_manifest',
        'driver_runs',
        'employees',
        'notification',
        'participant',
        'unattended_delivery',
        'users',
        'vehicle_capacity_types',
        'vehicle_garages',
        'vehicle_groups',
        'vehicle',
        'vehicles',
        'zipcode_groups'
      ]
    };
  },
  mounted() {
    this.params.resource_path = this.$route.params.changeLog;
  },
  watch: {
    'params.date'(value) {
      this.$emit('input', { start_date: value, end_date: value });
    },
    params: {
      deep: true,
      handler(value) {
        this.$emit('input', {
          date: `${value.date}  00:00:00`,
          action_type: value.action_type || null,
          resource_path: value.resource_path || null
        });
      }
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, default: () => {} },
    isLargeScreen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.filters
  justify-content: flex-end
  .autocomplete:first-child
    width: calc(30% - 0.5rem)
</style>
