<template>
  <Modal
    id="details-modal"
    :isOpen.sync="isModalOpen"
    :header="$t('dispatch.changeDetails')"
    size="is-large"
    :buttonLabel="$t('global.close')"
    :loading="loading"
    @save="() => this.$emit('update:isOpen', false)"
  >
    <Table
      :focusable="false"
      :perPage="20"
      :show-detail-icon="false"
      :data="data"
      class="dispatch-log-table"
      infiniteScroll
      ref="table"
    >
      <b-table-column :label="$t('menu.dat')" v-slot="props">
        {{ $t(props.row.id) || '-' }}
      </b-table-column>
      <b-table-column v-if="restrictions.old_values" :label="$tc('global.olddata')" v-slot="props">
        {{ props.row.old || '-' }}
      </b-table-column>
      <b-table-column v-if="restrictions.new_values" :label="$t('global.newdata')" v-slot="props">
        {{ props.row.new || '-' }}
      </b-table-column>
    </Table>
  </Modal>
</template>

<script>
import { Modal } from '@/components';
import { Table } from '@/components';
export default {
  components: {
    Modal,
    Table
  },
  data() {
    return {
      isModalOpen: false,
      loading: { save: false }
    };
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);

      if (!value) {
        this.restrictions.new_values = null;
        this.restrictions.new_values = null;
      }
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    data: { type: Array, default: null },
    restrictions: { type: Object }
  }
};
</script>

<style lang="sass" scoped>
.change-form
  width: calc(100% - 1rem)
</style>
