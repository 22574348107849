export const dataLogs = [
  { key: 'id', label: 'global.id' },
  { key: 'gender', label: 'global.gender.tableLabel' },
  { key: 'comments', label: 'global.comments' },
  { key: 'offset_tz', label: 'global.timeOffset' },
  { key: 'time_zone', label: 'dispatch.timezone' },
  { key: 'created_at', label: 'global.createdAt' },
  { key: 'created_by', label: 'global.createdBy' },
  { key: 'updated_at', label: 'global.updatedAt' },
  { key: 'updated_by', label: 'global.updatedBy' },
  { key: 'is_attended', label: 'global.isAttended' },
  { key: 'order_number', label: 'global.orderNumber' },
  { key: 'imei', label: 'global.imei' },
  { key: 'login_time', label: 'global.loginTime' },
  { key: 'vehicle_id', label: 'global.vehicleId' },
  { key: 'coded_route', label: 'global.codedRoute' },
  { key: 'secuence', label: 'global.secuence' },
  { key: 'eta', label: 'global.eta' },
  { key: 'participant_phone_number', label: 'global.participantPhoneNumber' },
  { key: 'participant_full_address', label: 'global.participantFullAddress' },
  { key: 'date', label: 'global.date' },
  { key: 'employee_id', label: 'global.employeeId' },
  { key: 'destination_full_address', label: 'global.destinationFullAddress' },
  { key: 'delivery_event_status_id', label: 'global.deliveryEventStatusId' },
  { key: 'unattended_delivery_id', label: 'global.unattendedDeliveryId' },
  { key: 'delivery_event_type_id', label: 'global.deliveryEventTypeId' },
  { key: 'destination_longitude', label: 'global.destinationLongitude' },
  { key: 'destination_latitude', label: 'global.destinationLatitude' },
  { key: 'delivery_manifest_id', label: 'global.deliveryManifestId' },
  { key: 'return_manifest_id', label: 'global.returnManifestId' },
  { key: 'estimated_distance', label: 'global.estimatedDistance' },
  { key: 'delivery_date', label: 'global.deliveryDate' },
  { key: 'participant_id', label: 'global.participantId' },
  { key: 'tracking_number', label: 'global.trackingNumber' },
  { key: 'participant_latitude', label: 'global.participantLatitude' },
  { key: 'refrigeration_needed', label: 'global.refrigerationNeeded' },
  { key: 'participant_full_name', label: 'global.participantFullName' },
  { key: 'participant_longitude', label: 'global.participantLongitude' },
  { key: 'close_door_pharmacy_id', label: 'global.closeDoorPharmacyId' },
  { key: 'participant_address_id', label: 'global.participantAddressId' },
  { key: 'destination_id', label: 'global.destinationId' },
  { key: 'tracking_token', label: 'global.trackingToken' },
  { key: 'time_offset', label: 'global.timeOffset' },
  { key: 'driver_run_id', label: 'global.driverRunId' },
  { key: 'estimated_time', label: 'global.estimatedTime' },
  { key: 'is_route_updated', label: 'global.is_route_updated' },
  { key: 'delivery_manifest_status_id', label: 'global.id' }
];
