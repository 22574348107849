<template>
  <Card class="card-log">
    <template #header>
      <div class="filters-section flex f-ai mb-1">
        <div class="ml-4 mr-4 mb-3">
          <StepCardHeader />
        </div>
        <Filters class="filter" :disabled="loading" @input="(value) => (params = value)" />
      </div>
    </template>
    <div>
      <MainTable ref="Table" :loading.sync="loading" :params="params" />
    </div>
  </Card>
</template>

<script>
import { Card, StepCardHeader } from '@/components';
import MainTable from './MainTable.vue';
import Filters from './Filters.vue';

export default {
  components: {
    Card,
    StepCardHeader,
    MainTable,
    Filters
  },
  data() {
    return {
      loading: false,
      params: { resource_path: '', action_type: '' }
    };
  }
};
</script>

<style lang="sass" scoped>
.card-log
  ::v-deep
    .card-header
      height: auto
      padding-top: 0
      padding-bottom: 0
    .card-content
      height: auto !important
.card
  height: calc(100% - 100px)
  .filters-section
    align-items: flex-end
    margin-top: 20px
    margin-left: -15px
    p
      color: $blue-700
      font-size: 14px
      margin-left: 0.5rem
  .filter
    ::v-deep
      width: 100% !important
      .autocomplete
        .label
          margin-bottom: 0
      .filters
        align-items: flex-end
      .field:nth-child(3)
        margin-top: 1.5rem
</style>

<style lang="sass">
.dark
  .card
    .filters-section
      p
        color: $main-background
</style>
